import Home from "./pages/Home/";
import { DotLoader } from 'react-spinners';
import banner from '../src/assets/banner-1.jpg';
import { useState, useEffect, useRef } from 'react';

function App() {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const startTime = useRef(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const diff = now - startTime.current;
      if (diff > 10000) {
        setLoading(false);
        return;
      }
      setProgress(Math.floor((diff / 5000) * 50));
    },);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    loading ? (
      <div
        style={{
          position: 'relative',
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
         <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${banner})`,
              backgroundSize: 'cover',
              filter: 'blur(15px)'
          }} />
        <div style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <DotLoader color="#DDA24F" size={88} />
          <p style={{
            marginTop: '80px',
            fontSize: '80px',
          }}>{progress}%</p>
        </div>
      </div>
    ) : (
      <Home />
    )
  );
}


export default App;
